import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import get from "lodash/get";
import Img from "gatsby-image";
import Layout from "../components/layout";

import heroStyles from "../components/hero.module.css";

class ProjectPostTemplate extends React.Component {
  render() {
    const project = get(this.props, "data.contentfulProjectPost");
    const siteTitle = get(this.props, "data.site.siteMetadata.title");

    return (
      <Layout location={this.props.location}>
        <div style={{ background: "#fff" }}>
          <Helmet title={`${project.title} | ${siteTitle}`} />
          <div className={heroStyles.hero}>
            <Img
              className={heroStyles.heroImage}
              alt={project.title}
              fluid={project.heroImage.fluid}
            />
          </div>
          <div className="wrapper">
            <h1 className="section-headline">{project.title}</h1>
            <p
              style={{
                display: "block",
              }}
            >
              {project.publishDate}
            </p>
            <div
              className={heroStyles.content}
              dangerouslySetInnerHTML={{
                __html: project.body.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProjectPostTemplate;

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    contentfulProjectPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
